import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-scroll";
import "../assets/css/global.css";
import "../assets/css/header.css";
import mainLogo from "./img/SalesX.svg";
const Header = () => {
  const [toggle, setToggle] = useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  return (
    <header
      className={
        scroll ? "header-main-area bg-black" : "header-main-area bg-black"
      }
    >
      <div className="container">
        <div className="header-inner-wrap">
          <div className="header-logo">
            <Link to="/">
              <img src={mainLogo} alt="" />
            </Link>
          </div>
          <div className="menuToggle" onClick={() => setToggle(!toggle)}>
            {toggle ? (
              <div className="navbar_mobile_close remove">
                <span></span>
                <span></span>
                <span></span>
              </div>
            ) : (
              <div className=" navbar_mobile_menu_bar">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
          </div>
          <nav
            className={
              toggle ? "navbar_mobile_menu show" : "navbar_mobile_menu hide"
            }
          >
            <div className="header-navbar container">
              <ul>
                <li>
                  <Link
                    activeClass="active"
                    to="hero"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                  >
                    Home
                  </Link>
                </li>

                <li>
                  <Link
                    activeClass="active"
                    to="feature"
                    spy={true}
                    smooth={true}
                    offset={-500}
                    duration={500}
                  >
                    Features
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="liveShare"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    Solutions
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="planning"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    Dashboard AD
                  </Link>
                </li>

                <li class="navbar-salesx-right-single-btn">
                  <div class="right-single-btn">
                    <button className=" btn feature-left-btn  hero btn--swap ">
                      <div>
                        <span className="hero-button-area-left-1">
                          <a
                            href="https://maacsolutions.com/contact"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            Request for Demo
                          </a>
                        </span>
                        <span
                          aria-hidden="true"
                          className="hero-button-area-left-2"
                        >
                          <a
                            href="https://maacsolutions.com/contact"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            Request for Demo
                          </a>
                        </span>
                      </div>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
