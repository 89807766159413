import { Col, Container, Row } from "react-bootstrap";
import heroLogo from "../../../Common/img/SalesX.svg";
import heroImage from "../img/heroRight.png";
import heroImageBrandIcon from "../img/heroRightBrand.svg";
const Hero = () => {
  return (
    <div className="hero-container-main-wrap" id="hero">
      <div className="fieldx-hero-inner-wrap">
        <Container>
          <div className="hero-inner-wrap">
            <div
              style={{ backgroundImage: `url(${heroImageBrandIcon})` }}
              className="hero-brand-icon-right"
            />
            <div className="hero-top-content-wrap-fieldx">
              <Row>
                <Col lg={12} className="my-auto">
                  <div className="hero-item-left-content">
                    <h3>
                      <img src={heroLogo} alt="" />
                    </h3>
                    <p>
                      Axs is the corridor of all access to issues we might need
                      to resolve in our professional and personal setup. All we
                      need is task identification and the brevity of the tasks
                      to be engaged in such a manner that issues get noticed,
                      tracked, escalated to the right audience, and gets
                      resolved.
                    </p>

                    <div className="hero-main-area-button">
                      <button className=" btn feature-left-btn  hero btn--swap ">
                        <div>
                          <span className="hero-button-area-left-1">
                            <a
                              href="https://maacsolutions.com/contact"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              Request for Demo
                            </a>
                          </span>
                          <span
                            aria-hidden="true"
                            className="hero-button-area-left-2"
                          >
                            <a
                              href="https://maacsolutions.com/contact"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              Request for Demo
                            </a>
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col lg={12} className="my-auto">
                <div className="hero-footer-image-wrap-fieldx-">
                  <Row>
                    <Col lg={8} className="my-auto">
                      <div className="hero-item-right-content image-bouns-animation">
                        <img src={heroImage} alt="" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Hero;
